import { useState } from 'react';
import {
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  FooterFbIcon,
  FooterInstaIcon,
  FooterPintresrIcon,
  FooterTwitterIcon,
  InstagramIcon,
  MailIcon,
  TiktokIcon,
} from 'src/assets/images/Svgs/svg';
import CustomModal from 'src/components/customModal/CustomModal';
import {
  GUEST_USER,
  LOYALTY_REWARDS_FAQS,
  PRIVACY_POLICY_ROUTE,
  ROUTE_CAREERS,
  ROUTE_LOCATIONS,
  ROUTE_LOYALTY,
  ROUTE_MENU,
  ROUTE_NUTRITION_MENU,
  ROUTE_OTHER_ITEM,
  ROUTE_REWARDS_REDEEM,
  ROUTE_TALK_TO_US,
  TERMS_ROUTE,
} from 'src/constants';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import { awsAssetsFetcher } from 'src/utils/awsAssetsFetcher';

import EmailListModel from './EmailListModel';
import styles from './footer.module.scss';
import appStoreImage from '../../../assets/images/home-page/appStore.png';
import playStoreImage from '../../../assets/images/home-page/playStore.png';
import { useAppSelector } from 'src/redux/store/store';
import { isExternal } from 'util/types';

const footerLinks = [
  {
    link: `${import.meta.env.VITE_REACT_APP_INDVIDUAL_DOMAIN}/menu`,
    text: 'MENU',
    alternateURL: { exist: false, link: null },
  },
  {
    link: `${import.meta.env.VITE_REACT_APP_INDVIDUAL_DOMAIN}/loyalty-faq`,
    text: 'REWARDS',
    alternateURL: { exist: true, link: ROUTE_LOYALTY },
  },
  {
    link: ROUTE_MENU,
    text: 'CATERING MENU',
    alternateURL: { exist: false, link: null },
  },
  {
    link: LOYALTY_REWARDS_FAQS,
    text: 'CATERING  Rewards',
    alternateURL: { exist: true, link: ROUTE_LOYALTY },
  },
  {
    link: 'https://app.everybite.com/widget/cafe-zupas?layout=card',
    text: 'NUTRITIONAL INFO',
  },
  {
    link: ROUTE_LOCATIONS,
    text: 'FIND A RESTAURANT ',
    alternateURL: { exist: false, link: null },
  },
  {
    link: ROUTE_CAREERS,
    text: 'CAREERS',
    alternateURL: { exist: false, link: null },
  },
  {
    link: ROUTE_TALK_TO_US,
    text: 'CONTACT US',
    alternateURL: { exist: false, link: null },
  },
  {
    link: PRIVACY_POLICY_ROUTE,
    text: 'PRIVACY POLICY',
    alternateURL: { exist: false, link: null },
  },
  {
    link: TERMS_ROUTE,
    text: 'TERMS & CONDITIONS',
    alternateURL: { exist: false, link: null },
  },
];



const footerLinksMob = [
  {
    link: `${import.meta.env.VITE_REACT_APP_INDVIDUAL_DOMAIN}/menu`,
    text: 'MENU',
    alternateURL: { exist: false, link: null },
  },
  {
    link: ROUTE_NUTRITION_MENU,
    text: 'NUTRITIONAL INFO ',
    alternateURL: { exist: false, link: null },
  },
  {
    link: ROUTE_MENU,
    text: 'CATERING MENU',
    alternateURL: { exist: false, link: null },
  },
  {
    link: ROUTE_LOCATIONS,
    text: 'FIND A RESTAURANT ',
    alternateURL: { exist: false, link: null },
  },
  {
    link: `${import.meta.env.VITE_REACT_APP_INDVIDUAL_DOMAIN}/loyalty-faq`,
    text: 'REWARDS',
    alternateURL: { exist: true, link: ROUTE_LOYALTY },
  },
  {
    link: ROUTE_CAREERS,
    text: 'CAREERS',
    alternateURL: { exist: false, link: null },
  },
  {
    link: LOYALTY_REWARDS_FAQS,
    text: 'CATERING  Rewards',
    alternateURL: { exist: true, link: ROUTE_LOYALTY },
  },
  {
    link: ROUTE_TALK_TO_US,
    text: 'CONTACT US',
    alternateURL: { exist: false, link: null },
  },
  {
    link: PRIVACY_POLICY_ROUTE,
    text: 'PRIVACY POLICY',
    alternateURL: { exist: false, link: null },
  },
  {
    link: TERMS_ROUTE,
    text: 'TERMS & CONDITIONS',
    alternateURL: { exist: false, link: null },
  },
];
const socialIconsArray = [
  {
    link: 'https://www.tiktok.com/@cafezupas',
    icon: <TiktokIcon />,
    socail_alt_text: `tiktok`,
  },
  {
    link: `https://www.instagram.com/cafezupas/`,
    icon: <InstagramIcon />,
    socail_alt_text: `instargram`,
  },
];

const Footer = () => {
  const { user } = useAppSelector((state) => state?.user);
  const location = useLocation();
  const isItMobile = useCheckMobileScreen();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleClick = () => setShow(true);
  const [showGlutenModal, setShowGlutenModal] = useState(false);

  const loyaltyRedirectionLink = (item) => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to top
    return user?.type === GUEST_USER || user?.logged_out
      ? item.link
      : item.alternateURL.exist
        ? item.alternateURL.link
        : item.link;
  };
  return (
    <>
      <div className={`${styles.footerWrapper}`}>
        <Container fluid className="cz-px-30">
          <Row className="align-items-start">
            {!isItMobile ? (
              <div>
                <h1 className={styles.footerTitle}>
                  find the good life everywhere{' '}
                </h1>
                <ul className={styles.socialIcons}>
                  <li>
                    <a onClick={handleClick}>
                      {' '}
                      <MailIcon />{' '}
                    </a>
                  </li>
                  {socialIconsArray.map((icon) => {
                    return (
                      <li>
                        <a href={icon.link} target="_blanl">
                          {' '}
                          {icon.icon}{' '}
                        </a>
                      </li>
                    );
                  })}
                </ul>

                <ul className={`${styles.footerLinks} ps-0`}>
                  {footerLinks.slice(0, 5).map((link) => {
                    const isExternal =
                      link.link.startsWith('http') ||
                      link.link.startsWith('https');
                    return (
                      <li key={link.text}>
                        {' '}
                        {link.link === ROUTE_NUTRITION_MENU || isExternal ? (
                          <a
                            href={link.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.text}
                          </a>
                        ) : (
                          <Link to={'' + loyaltyRedirectionLink(link)}>
                            {link.text}
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>

                <ul className={`${styles.footerLinks} ps-0`}>
                  {footerLinks.slice(5).map((link) => {
                    return (
                      <li>
                        <Link to={link.link}>{link.text}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <div>
                <ul className={`${styles.socialIcons} ps-0`}>
                  <li>
                    <a onClick={handleClick}>
                      {' '}
                      <MailIcon />{' '}
                    </a>
                  </li>
                  {socialIconsArray.map((icon) => {
                    return (
                      <li>
                        <a href={icon.link}> {icon.icon} </a>
                      </li>
                    );
                  })}
                </ul>

                <h1 className={`${styles.footerTitle} mb-0`}>
                  find the good life everywhere{' '}
                </h1>
                <div className="cz-pt-24 cz-pb-32">
                  <h3 className="font-trade-gothic-next-condensed f-s20 f-w8 text-center clr-white">
                    DOWNLOAD THE APP{' '}
                  </h3>
                  <div className="d-flex align-items-center  gap-2 justify-content-center mt-4">
                    <a
                      href={
                        'https://apps.apple.com/us/app/caf%C3%A9-zupas-catering/id1445666529'
                      }
                      target={'_blank'}
                      rel="noopener noreferrer"
                    >
                      <img
                        src={appStoreImage}
                        width={150}
                        alt="app store icon"
                      />
                    </a>
                    <a
                      href={
                        'https://play.google.com/store/apps/details?id=com.cafezupas&hl=en&pli=1'
                      }
                      target={'_blank'}
                      rel="noopener noreferrer"
                    >
                      <img
                        src={playStoreImage}
                        width={150}
                        alt="play store icon"
                      />
                    </a>
                  </div>
                </div>

                <ul className={`${styles.footerLinks} row ps-0 cz-pt-24`}>
                  {footerLinksMob.map((link) => {
                    const isExternal =
                      link.link.startsWith('http') ||
                      link.link.startsWith('https');
                    return (
                      <li key={link.text} className="col-6">
                        {' '}
                        {link.link === ROUTE_NUTRITION_MENU || isExternal ? (
                          <a
                            href={link.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link.text}
                          </a>
                        ) : (
                          <Link to={'' + loyaltyRedirectionLink(link)}>
                            {link.text}
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </Row>
        </Container>
      </div>
      <Row>
        <Col xs="12" sm="12" md="6">
          <EmailListModel showPopup={show} closePopup={handleClose} />
        </Col>
      </Row>
      <Modal
        show={showGlutenModal}
        title={'*Gluten Sensitive'}
        onHide={() => {
          setShowGlutenModal(false);
        }}
        className="theme-modal-style app_container_wrap  border-radius-15 "
        centered
      >
        <ModalHeader className="pb-0">
          <button
            type="button"
            className="btn modal-close-button pe-0"
            onClick={() => {
              setShowGlutenModal(false);
            }}
          >
            close
          </button>
          <h5
            className={`f-s22 font-Cls  mb-0 text-capitalize text-center w-100 mt-4`}
          >
            *Gluten Sensitive
          </h5>
        </ModalHeader>
        <ModalBody className="px-0 ">
          <p className="text-justify ">
            Even with strict adherence toward maintaining clean and organized
            kitchens, we have too much wheat and gluten present to be able to
            eliminate the cross-contamination on our equipment and food
            preparation areas. If you are highly allergic or intolerant to
            gluten, we ask you to exercise judgment regarding your individual
            needs.
          </p>
        </ModalBody>
      </Modal>
    </>
  );
};
export default Footer;
