import React, { Suspense } from 'react';
import { Redirect, Switch, useLocation } from 'react-router';
import FloatingActionBtn from 'src/components/FloatingActionBtn/FloatingActionBtn';
import Footer from 'src/components/Layout/Footer/Footer';
import Header from 'src/components/Layout/Header/Header';
import Loader from 'src/components/Loader/Loader';
import RequireAuth from 'src/components/RequireAuth';
import MasterContainer from 'src/containers/ItemDetail/MasterContainer';
import { bugSnagInititalize } from 'src/helper/bugSnagHelper';
import {
  getAddPaymentUser,
  isGuestUser,
  removeTextToPayUserSessionDetail,
} from 'src/helper/helperMethods';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';

import RouteWithReactHelmet from '../../components/ReactHelmetHOC/ReactHelmetWrapper';
import * as ROUTE_CONSTANT from '../../constants';
import TextToPayHeader from 'src/components/Layout/Header/TextToPayHeader';
import AddPayment from 'src/Features/order/addPayment';
import LinkExpired from 'src/containers/fallBacks/LinkExpired';
import {
  removeAddPaymentUserData,
  resetCheckoutForTextToPayFlow,
} from 'src/redux/slices/addPayment/addPaymentSlice';
import RequestPayment from 'src/Features/order/requestPayment';
import * as CONSTANT from '../../constants';
import { CampaignTriggerProvider } from 'src/context/CampaignTriggerContext';
import RequiresLocation from 'src/components/RequiresLocation';
const ErrorBoundaryBugSnag = bugSnagInititalize();

const Login = React.lazy(
  () => import('../../Features/AccountSetup/components/Login'),
);
const HomeContainer = React.lazy(() => import('../../components/Home/Home'));

const OurKitchen = React.lazy(() => import('../../components/Home/OurKItchen'));
const Fundraising = React.lazy(
  () => import('../../components/Home/Fundrasing/Fundrasing'),
);
const Careers = React.lazy(() => import('../../components/Home/Career/Career'));
const CreateAccount = React.lazy(
  () => import('../../Features/AccountSetup/components/CreateAccount'),
);
const Welcome = React.lazy(
  () => import('../../Features/AccountSetup/components/Welcome'),
);
const ForgotPassword = React.lazy(
  () => import('../../Features/AccountSetup/components/ForgotPassword'),
);

const NewPassword = React.lazy(
  () => import('../../Features/AccountSetup/components/NewPassword'),
);
const Menu = React.lazy(() => import('../../Features/Menu/Menu'));
const Loyalty = React.lazy(() => import('../../Features/Rewards//Rewards'));
const noSearchResult = React.lazy(
  () => import('../../components/SearchResult/NoResult'),
);
const otherManuItems = React.lazy(
  () => import('src/containers/OtherManuItems'),
);
const boxLunchesContainer = React.lazy(
  () => import('src/containers/ItemDetail/BoxLunchesContainer'),
);

const ProfileDetail = React.lazy(
  () => import('src/Features/profileDetail/ProfileDetail'),
);

const CaterWithUs = React.lazy(
  () => import('src/components/CaterWithUs/CaterWithUs'),
);

const ContactUs = React.lazy(() => import('src/Features/ContactUs/ContactUs'));
const privacyPolicy = React.lazy(
  () => import('src/Features/PrivacyPolicy/PrivacyPolicy'),
);
const terms = React.lazy(() => import('src/Features/Terms/Terms'));
const CateringOrder = React.lazy(
  () => import('src/containers/StartOrder/CateringOrder'),
);
const Rewards = React.lazy(() => import('src/Features/Rewards/Rewards'));
const RewardsFaqs = React.lazy(
  () => import('src/components/Home/CateringRewardsPage/CateringRewardsPage'),
);
const ViewMoreRecentOrders = React.lazy(
  () =>
    import(
      'src/Features/profileDetail/RecentOrders/ViewMoreRecentOrders/ViewMoreRecentOrders'
    ),
);
const ViewMoreFutureOrders = React.lazy(
  () =>
    import(
      'src/Features/profileDetail/FutureOrders/MoreFutureOrders/MoreFutureOrders'
    ),
);

const AccountDetails = React.lazy(
  () =>
    import(
      'src/Features/profileDetail/HouseAccounts/AccountDetails/AccountDetails'
    ),
);

const CreditDetails = React.lazy(
  () =>
    import(
      'src/Features/profileDetail/HouseAccounts/AccountDetails/CreditDetails'
    ),
);

const RecentOrders = React.lazy(
  () =>
    import(
      'src/Features/profileDetail/HouseAccounts/AccountDetails/RecentOrders'
    ),
);
const GroupOrderDeetails = React.lazy(
  () => import('src/Features/order/groupOrderDetails'),
);
const HouseAccountUserListing = React.lazy(
  () =>
    import(
      'src/Features/profileDetail/HouseAccounts/HouseAccountUsers/HouseAccountUserListing'
    ),
);

const OrderReciept = React.lazy(
  () => import('src/Features/profileDetail/orderReciept/orderReciept'),
);

const ThankYou = React.lazy(
  () => import('src/components/OrderTracking/ThankYou'),
);

const PublicOrderTrackingContainer = React.lazy(
  () => import('src/publicOrderTracking/publicOrderTracking'),
);

const Checkout = React.lazy(() => import('src/Features/order/checkout'));
// const GroupOrderCheckout = React.lazy(
//   () => import('src/Features/Ordering/Checkout/GroupOrderCheckout'),
// );

const GroupOrder = React.lazy(
  () => import('src/Features/GroupOrder/components/GroupOrder'),
);

const Page404 = React.lazy(() => import('src/Features/Page404/page404'));

const Location = React.lazy(() => import('src/Features/Location/Location'));
const Routes: React.FunctionComponent = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const excludePgaes = ['/location', '/checkout'];
  const excludePgaesForAddPayment = [
    ROUTE_CONSTANT.ADD_PAYMENT_THANK_YOU,
    ROUTE_CONSTANT.ADD_PAYMENT_LINK_EXPIRED,
    ROUTE_CONSTANT.REQUEST_PAYMENT_LINK_EXPIRED,
    ROUTE_CONSTANT.REQUEST_PAYMENT_THANK_YOU,
  ];
  const addPayment =
    location.pathname.includes(ROUTE_CONSTANT.ROUTE_ADD_PAYMENT) ||
    location.pathname.includes(ROUTE_CONSTANT.ROUTE_REQUEST_PAYMENT);

  const addPaymentThankYouAndExpireLink = !excludePgaesForAddPayment.includes(
    location.pathname,
  );
  const showFooter = !excludePgaes.includes(location.pathname);
  const hideFab =
    location.pathname === ROUTE_CONSTANT.ROUTE_CHECKOUT ||
    location.pathname === ROUTE_CONSTANT.ROUTE_THANK_YOU ||
    addPayment;
  const { user } = useAppSelector((state) => state.user);
  const addPaymentUser = useAppSelector(
    (state) => state.addPayment.addPaymentUser,
  );
  const addPaymentUserSession = getAddPaymentUser();

  if (!addPayment && addPaymentUser?.id) {
    dispatch(removeAddPaymentUserData());
    dispatch(resetCheckoutForTextToPayFlow());
  }

  if (!addPayment && addPaymentUserSession?.customer_id) {
    removeTextToPayUserSessionDetail();
    sessionStorage.removeItem(CONSTANT.ERROR_MESSAGE);
  }

  return (
    <Suspense fallback={<Loader />}>
      {addPayment ? <TextToPayHeader /> : <Header />}
      <ErrorBoundaryBugSnag FallbackComponent={Page404}>
        <Switch>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_HOME}
            // path={ROUTE_CONSTANT.ROUTE_WELCOME_SCREEN}
            exact={true}
            routeComponent={HomeContainer}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_CATER_WITH_US}
            exact={true}
            routeComponent={CaterWithUs}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_OUR_KITCHEN}
            exact={true}
            routeComponent={OurKitchen}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_FUNDRAISING}
            exact={true}
            routeComponent={Fundraising}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_CAREERS}
            exact={true}
            routeComponent={Careers}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_LOGIN}
            exact={true}
            routeComponent={Login}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_CREATE_ACCOUNT}
            exact={true}
            routeComponent={CreateAccount}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_WELCOME_SCREEN}
            exact={true}
            routeComponent={Welcome}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_FORGOT_PASSWORD}
            exact={true}
            routeComponent={ForgotPassword}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_NEW_PASSWORD}
            exact={true}
            routeComponent={NewPassword}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_MENU}
            exact={true}
            routeComponent={Menu}
          ></RouteWithReactHelmet>
          <RequireAuth
            path={ROUTE_CONSTANT.ROUTE_LOYALTY}
            exact={true}
            component={Loyalty}
          ></RequireAuth>
          <RouteWithReactHelmet
            path={`${ROUTE_CONSTANT.ROUTE_MENU}/:selected_category?`}
            exact={true}
            routeComponent={Menu}
          ></RouteWithReactHelmet>

          <RequireAuth
            path={ROUTE_CONSTANT.ROUTE_PROFILE_DETAILS}
            exact={true}
            component={ProfileDetail}
          ></RequireAuth>
          <RequireAuth
            path={`${ROUTE_CONSTANT.ROUTE_GROUP_ORDER_DETAILS}/:id`}
            exact={true}
            component={GroupOrderDeetails}
          ></RequireAuth>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_TALK}
            exact={true}
            routeComponent={ContactUs}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_PRIVACY_POLICY}
            exact={true}
            routeComponent={privacyPolicy}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_TERMS}
            exact={true}
            routeComponent={terms}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_PROFILE_RECENT_ORDERS}
            exact={true}
            routeComponent={ViewMoreRecentOrders}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_PROFILE_FUTURE_ORDERS}
            exact={true}
            routeComponent={ViewMoreFutureOrders}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={`${ROUTE_CONSTANT.PRINT_RECEIPT}/:id`}
            exact={true}
            routeComponent={OrderReciept}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_NO_SEARCH_RESULT}
            exact={true}
            routeComponent={noSearchResult}
          ></RouteWithReactHelmet>

          <RequiresLocation
            path={`${ROUTE_CONSTANT.ROUTE_OTHER_ITEM}/:id`}
            exact={true}
            component={MasterContainer}
          ></RequiresLocation>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_MAP_LOCATION}
            exact={true}
            routeComponent={Location}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={`${ROUTE_CONSTANT.ROUTE_LOCATION_COPY}/info/:id`}
            exact={true}
            routeComponent={Location}
          ></RouteWithReactHelmet>
          <RequireAuth
            path={`${ROUTE_CONSTANT.HOUSE_ACCOUNT_DETAIL}/:id`}
            exact={true}
            component={AccountDetails}
          ></RequireAuth>
          <RequireAuth
            path={`${ROUTE_CONSTANT.CREDIT_DETAILS}/:id`}
            exact={true}
            component={CreditDetails}
          ></RequireAuth>
          <RequireAuth
            path={`${ROUTE_CONSTANT.HOUSE_ACCOUNT_RECENT_ORDERS}/:id`}
            exact={true}
            component={RecentOrders}
          ></RequireAuth>
          <RequireAuth
            path={`${ROUTE_CONSTANT.HOUSE_ACCOUNT_RECENT_ORDERS}`}
            exact={true}
            component={RecentOrders}
          ></RequireAuth>
          <RequireAuth
            path={`${ROUTE_CONSTANT.HOUSE_ACCOUNT_USER_LISTING}/:id`}
            exact={true}
            component={HouseAccountUserListing}
          ></RequireAuth>
          <RequireAuth
            path={ROUTE_CONSTANT.ROUTE_THANK_YOU}
            exact={true}
            component={ThankYou}
          ></RequireAuth>
          <RouteWithReactHelmet
            path={`${ROUTE_CONSTANT.ROUTE_ORDER_TRACKING}/:id`}
            exact={true}
            routeComponent={PublicOrderTrackingContainer}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_START_CATERING_ORDER}
            exact={true}
            routeComponent={CateringOrder}
          ></RouteWithReactHelmet>

          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.LOYALTY_REWARDS}
            exact={true}
            routeComponent={Rewards}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={`${ROUTE_CONSTANT.ADD_PAYMENT_LINK_EXPIRED}`}
            exact={true}
            routeComponent={LinkExpired}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={`${ROUTE_CONSTANT.ADD_PAYMENT_THANK_YOU}`}
            exact={true}
            routeComponent={ThankYou}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={`${ROUTE_CONSTANT.ROUTE_ADD_PAYMENT}/:id`}
            exact={true}
            routeComponent={AddPayment}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={`${ROUTE_CONSTANT.REQUEST_PAYMENT_LINK_EXPIRED}`}
            exact={true}
            routeComponent={LinkExpired}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={`${ROUTE_CONSTANT.REQUEST_PAYMENT_THANK_YOU}`}
            exact={true}
            routeComponent={ThankYou}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={`${ROUTE_CONSTANT.ROUTE_REQUEST_PAYMENT}/:id`}
            exact={true}
            routeComponent={RequestPayment}
          ></RouteWithReactHelmet>
          <RequireAuth
            path={ROUTE_CONSTANT.ROUTE_CHECKOUT}
            exact={true}
            component={Checkout}
          ></RequireAuth>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_GROUP_ORDER}
            exact={true}
            routeComponent={GroupOrder}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.LOYALTY_REWARDS_FAQS}
            exact={true}
            routeComponent={RewardsFaqs}
          ></RouteWithReactHelmet>
          <RouteWithReactHelmet
            path={ROUTE_CONSTANT.ROUTE_404}
            exact={true}
            routeComponent={Page404}
          ></RouteWithReactHelmet>
          <Redirect to="/menu" />
        </Switch>
        {showFooter || (!addPayment && !addPaymentThankYouAndExpireLink) ? (
          <Footer />
        ) : null}

        {!isGuestUser(user) && !hideFab && <FloatingActionBtn />}
      </ErrorBoundaryBugSnag>
    </Suspense>
  );
};

export default Routes;
