import { useState } from 'react';

import {
  ADD_PAYMENT_USER_CART,
  DELIVERY_DESTINATION_ID,
  DELIVERY_ORDER_TYPE,
  IN_STORE_DESTINATION_ID,
  ROUTE_CHECKOUT,
} from '../constants';
import { IGetCartPayload } from '../models/order.model';
import { getCart } from '../react-query-hooks/Cart/useGetCartItems';
import { clearCart, updateCartOrder } from '../redux/slices/cartSlice';
import { useAppDispatch, useAppSelector } from '../redux/store/store';
import { IOfferItem } from 'src/models/rewards.model';
import useRedeemOffer from './useRedeemOffer';
import { useLocation } from 'react-router';
import { updateStartedCheckoutBrazeEvent } from 'src/redux/slices/itemSlice';
import { getOrderDetails } from 'src/react-query-hooks/useGetOrderDetails';

const useSyncCart = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);

  const location = useLocation();

  const cart = useAppSelector((state) => state.cart);
  const startedCheckoutBrazeEvent = useAppSelector(
    (state) => state.Items.startedCheckoutBrazeEvent,
  );

  const { redeemOffer, removeOffer, clearAppliedOffer } = useRedeemOffer();

  const [loading, setLoading] = useState<boolean>(false);

  const syncCart = async (
    location_id: string,
    persistOffer?: boolean,
    selectedOfferItem?: IOfferItem,
    allOffers?: IOfferItem[],
  ) => {
    setLoading(true);
    try {
      if (!user.id) return;
      const payload: IGetCartPayload = {
        location_id: location_id,
        customer_id: user.id,
      };

      if (cart.orderType) {
        payload.order_type =
          cart.orderType === DELIVERY_ORDER_TYPE
            ? DELIVERY_DESTINATION_ID
            : IN_STORE_DESTINATION_ID;
      }
      if (persistOffer) {
        payload.persist_offer = true;
      }
      const response = await getCart(payload);
      if (response && response.items) {
        await dispatch(updateCartOrder(response));
        if (allOffers && !selectedOfferItem) {
          selectedOfferItem = allOffers?.find(
            (offer: IOfferItem) =>
              response.root_offer_id === offer.loyalty_offer_id ||
              response.root_offer_id === offer.loyalty_root_offer_id,
          );
        }
        if (selectedOfferItem) {
          selectedOfferItem.in_cart = false;
          const { failedOfferCriteria } = redeemOffer({
            offerItem: selectedOfferItem,
            cartItems: response.items,
            selectedOfferItem,
            subTotal: cart.rewardsDiscountedSubTotal,
          });
          if (failedOfferCriteria) {
            removeOffer(selectedOfferItem, true);
            clearAppliedOffer();
            if (allOffers) {
              //need to resync cart with B.E as to update billing on checkout page
              const updatedResponse = await getCart(payload);
              if (updatedResponse && updatedResponse.items)
                dispatch(updateCartOrder(updatedResponse));
            }
          }
        }
        setTimeout(() => {
          if (
            location.pathname.includes(ROUTE_CHECKOUT) &&
            startedCheckoutBrazeEvent.isPending
          ) {
            dispatch(
              updateStartedCheckoutBrazeEvent({
                triggerEvent: true,
              }),
            );
          }
        }, 2000);
      } else {
        await dispatch(clearCart());
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const syncCartForAddPayment = async (
    verifyPaymentLinkData,
    callOrderDetailApi: boolean,
    persistOffer?: boolean,
  ) => {
    setLoading(true);
    try {
      if (!verifyPaymentLinkData.customer_id) return;
      const payload: IGetCartPayload = {
        location_id: verifyPaymentLinkData.location_id,
        customer_id: verifyPaymentLinkData.customer_id,
        order_type: verifyPaymentLinkData.destination_id,
        cart_with_id: verifyPaymentLinkData?.order?.cart_id,
      };

      if (persistOffer) {
        payload.persist_offer = true;
      }
      let response;
      if (callOrderDetailApi) {
        response = await getOrderDetails({
          orderId: verifyPaymentLinkData.order_id,
        });
      } else {
        response = await getCart(payload);
      }
      if (response && response.items) {
        const cart = {
          items: response.items,
          orderId: response.order_id,
          tax: response.tax,
          discount: response.discount,
          is_edit_order: response.is_edit_order,
          cartId: response.id,
          service_fee: response.service_fee,
          delivery_fee: response.delivery_fee,
          gross_sales: response.gross_sales,
          net_sales: response.net_sales,
          useSessionCart: true,
        };
        sessionStorage.setItem(ADD_PAYMENT_USER_CART, JSON.stringify(cart));
      } else {
        sessionStorage.removeItem(ADD_PAYMENT_USER_CART);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return { syncCart, syncCartForAddPayment, loading };
};

export default useSyncCart;
