import { useEffect, useState } from 'react';
import CustomModal from 'src/components/customModal/CustomModal';
import ItemOrderModal from 'src/components/ItemOrderModal/ItemOrderModal';
import ItemPickupDeliveryModal from 'src/components/ItemOrderModal/ItemPickupDeliveryModal';
import {
  getUser,
  isCartItemsUnavailable,
  isCartModifiersUnavailable,
  persistZero,
  roundNumber,
} from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';
import useSyncCart from 'src/hooks/useSyncCart';
import useExitEditOrder from 'src/react-query-hooks/useExistEditOrder';
import BigJs from 'big.js';

import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import {
  setShowCart,
  updateRewardDiscountedSubTotal,
} from 'src/redux/slices/cartSlice';
import {
  DELIVERY_FEE_PERCENT,
  DELIVERY_ORDER_TYPE,
  GUEST_USER,
  MINIMUM_SUBTOTAL,
  OFFER_TYPE_FIXED_DISCOUNT,
  PICK_UP_ORDER_TYPE,
  ROUTE_LOGIN,
  SERVICE_FEE,
} from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../redux/store/store';
import GuestCheckoutModal from '../../GuestCheckout/GuestCheckoutModal';

import {
  resetCheckout,
  updateCartBilling,
} from 'src/redux/slices/checkoutSlice';
import styles from './billing.module.scss';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import { brazeLogCustomEventCheckoutStarted } from 'src/helper/brazeHelper';
import { updateStartedCheckoutBrazeEvent } from 'src/redux/slices/itemSlice';

/*
 * TODO: use context to get list of items added in cart and calculate the bill
 */
interface IBillingProps {
  handleShowPickupMethodModal?: boolean;
  handleClosePickupMethodModal?: boolean;
  handleShowDeliveryMethodModal?: boolean;
  handleCloseDeliveryMethodModal?: boolean;
  isOrderTypeSelected?: boolean;
  orderType?: string;
  cartItems: any;
  isOrdered?: boolean;
  authInfo?: any;
  orderTippingAmount?: number;
  isCheckoutBilling?: boolean;
  order?: any;
  tax?: number;
  titlespace?: string;
  setTotal?: (number) => void;
  setSubTotal?: (subTotal: number) => void; // Used for offer redemption calculation
  setDiscountedSubTotal?: (value: number) => void;
  handleTax?: boolean;
  offerDiscount?: number;
  disableCheckout?: boolean;
  offerDiscountType?: string;
  className?: string;
  tip?: any;
  selectedTaxExempt?: string;
  isTaxExempt?: boolean;
  isTaxExempted?: boolean;
  isCart?: boolean;
  cartInCaseOfAddPaymentFlow?: any;
  remainingAmount?: number;
  handlePaymentForAddPaymentUser?: boolean;
  sessionCart?: any;
}

const Billing = (props: IBillingProps) => {
  const {
    handleShowPickupMethodModal,
    handleShowDeliveryMethodModal,
    isOrderTypeSelected,
    orderType,
    cartItems = [],
    isOrdered,
    orderTippingAmount,
    isCheckoutBilling,
    tax,
    order,
    setTotal,
    setSubTotal,
    setDiscountedSubTotal,
    offerDiscount,
    disableCheckout,
    handleTax = true,
    className,
    offerDiscountType,
    selectedTaxExempt = 'true',
    isTaxExempt = false,
    isTaxExempted = false,
    isCart = false,
    cartInCaseOfAddPaymentFlow,
    remainingAmount,
    handlePaymentForAddPaymentUser,
    sessionCart,
  } = props;

  const startedCheckoutBrazeEvent = useAppSelector(
    (state) => state.Items.startedCheckoutBrazeEvent,
  );

  const [openPickupMethodModal, setOpenPickupMethodModal] = useState(false);
  const history = useHistory();
  const [openDeliveryMethodModal, setOpenDeliveryMethodModal] = useState(false);
  const [changeOrderType, setChangeOrderType] = useState(false);
  const locationInfo = useAppSelector((state) => state.location);
  const [displaySubTotal, setDisplaySubTotal] = useState<number>(0);
  const [displayDiscount, setDisplayDiscount] = useState<number>(0);
  // netSales = subTotal - discount
  const [netSales, setNetSales] = useState<number>(0);
  const [showContactForm, setShowContactForm] = useState<boolean>(false);
  const [showContactInfo, setShowContactInfo] = useState<boolean>(false);
  const CartBilling = useAppSelector((state) => state.cart);
  const handleShowContactForm = () => setShowContactForm(true);
  const handleCloseContactForm = () => setShowContactForm(false);
  const cartTax = cartInCaseOfAddPaymentFlow
    ? cartInCaseOfAddPaymentFlow.tax
    : useAppSelector((state) => state.cart.tax);
  const isEditOrder = cartInCaseOfAddPaymentFlow
    ? cartInCaseOfAddPaymentFlow.is_edit_order
    : useAppSelector((state) => state.cart.is_edit_order);
  const orderId = cartInCaseOfAddPaymentFlow
    ? cartInCaseOfAddPaymentFlow.order_id
    : useAppSelector((state) => state.cart.orderId);
  const location_id = locationInfo.selectedStore?.id;
  const { syncCart, loading: syncingCart } = useSyncCart();

  const user = getUser();
  const savedOrderType = orderType;

  const isItMobile = useCheckMobileScreen();

  useEffect(() => {
    if (startedCheckoutBrazeEvent.triggerEvent) {
      const total: any = persistZero(roundNumber(calculateTotal(), 2));
      brazeLogCustomEventCheckoutStarted(
        CartBilling,
        {
          discount: CartBilling?.discount,
          tax: CartBilling.tax,
          subTotal: CartBilling.gross_sales,
          total,
        },
        isItMobile,
        CartBilling?.orderDetails?.PickupSlotDetails,
        locationInfo.selectedStore,
      );
      dispatch(updateStartedCheckoutBrazeEvent({ isSent: true }));
    }
  }, [startedCheckoutBrazeEvent.triggerEvent]);

  useEffect(() => {
    calculateSubTotal();
  }, [cartItems, offerDiscount]);

  useEffect(() => {
    if (handleShowPickupMethodModal) {
      setOpenPickupMethodModal(true);
    }
    if (handleShowDeliveryMethodModal) {
      setOpenDeliveryMethodModal(true);
    }
  }, [handleShowPickupMethodModal, handleShowDeliveryMethodModal]);
  function calculateTax(): number {
    if ((handleTax && !isTaxExempt) || !selectedTaxExempt) {
      return cartTax;
    } else {
      return 0;
    }
  }
  const { mutateAsync, isLoading: isExitOrderLoading } = useExitEditOrder();

  const handleExitEditOrder = () => {
    return mutateAsync(
      { id: orderId },
      {
        onSuccess: (data) => {
          dispatch(resetCheckout());
          syncCart(location_id);
          Toast_Func({
            status: true,
            message: 'Closed edit order successfully',
          });
        },
        onError: (error) => {
          Toast_Func({
            status: false,
            message: 'An error occurred',
          });
        },
      },
    );
  };
  const toFixedNumber = (number: number): number => {
    return Number(roundNumber(number, 2));
  };
  const calculateDeliveryFee = () => {
    if (isCheckoutBilling)
      return sessionCart?.useSessionCart
        ? sessionCart?.delivery_fee
        : CartBilling?.delivery_fee;
    if (CartBilling.free_delivery) return 0;
    const discountedAmount = BigJs(displaySubTotal).minus(
      displayDiscount ? displayDiscount : 0,
    );
    const calculatedDeliveryFee = discountedAmount
      .times(DELIVERY_FEE_PERCENT)
      .toNumber();
    return toFixedNumber(calculatedDeliveryFee);
  };

  const calculateOfferDiscount = (totalAmount: number) => {
    if (offerDiscountType === OFFER_TYPE_FIXED_DISCOUNT) {
      return offerDiscount;
    } else {
      const discount = (totalAmount * offerDiscount) / 100;
      return discount;
    }
  };

  const requestPaymentBilling = () => {
    const discountSubTotalValue = sessionCart?.net_sales;
    setDisplaySubTotal(sessionCart?.gross_sales);
    setDisplayDiscount(sessionCart?.discount);
    setDiscountedSubTotal(toFixedNumber(discountSubTotalValue));
    setSubTotal?.(sessionCart?.gross_sales);
    setNetSales(sessionCart?.net_sales);
  };

  function calculateSubTotal() {
    if (isCheckoutBilling) {
      if (sessionCart?.useSessionCart) {
        requestPaymentBilling();
        return;
      } else {
        setDisplaySubTotal(CartBilling?.gross_sales);
        setDisplayDiscount(CartBilling?.discount);
        setDiscountedSubTotal(toFixedNumber(CartBilling?.net_sales));
        setNetSales(CartBilling?.net_sales);
        setSubTotal?.(CartBilling?.gross_sales);
        return;
      }
    }
    if (cartItems.length > 0) {
      let displayTotal = 0;
      cartItems.forEach((item: any) => {
        const price = item.gross_price ?? item.display_price;
        displayTotal += toFixedNumber(price * item.quantity);
      });
      setSubTotal?.(displayTotal);
      dispatch(updateRewardDiscountedSubTotal(displayTotal));
      setDisplaySubTotal(toFixedNumber(displayTotal));
      const discountByOffer = calculateOfferDiscount(displayTotal);
      setDisplaySubTotal(toFixedNumber(displayTotal));
      if (discountByOffer > displayTotal) {
        setDiscountedSubTotal?.(0);
        setDisplayDiscount(toFixedNumber(displayTotal));
      } else {
        setDisplayDiscount(toFixedNumber(discountByOffer));
        setDiscountedSubTotal?.(toFixedNumber(displayTotal - discountByOffer));
      }
    } else {
      setSubTotal?.(0);
      setDisplaySubTotal(0);
      setDisplayDiscount(0);
    }
  }
  const dispatch = useAppDispatch();
  useEffect(() => {
    calculateTotal();
  }, [cartTax]);

  const onCheckout = async () => {
    if (
      displaySubTotal < MINIMUM_SUBTOTAL &&
      orderType === DELIVERY_ORDER_TYPE
    ) {
      Toast_Func({
        status: false,
        message: 'Subtotal should be greater then $' + MINIMUM_SUBTOTAL,
      });
      return;
    }
    if (user.type === GUEST_USER) {
      //check if user is guest
      history.push(ROUTE_LOGIN);
      dispatch(setShowCart(false));
      Toast_Func({
        status: false,
        message: 'Login Required',
      });
      // if (user.isInfo) setShowContactInfo(true);
      // else setShowContactInfo(false);
      // handleShowContactForm();
    } else {
      showOrderTypeModal();
    }
  };

  const calculateDiscountedTotal = () => {
    const delivery_fee = CartBilling.free_delivery ? 0 : calculateDeliveryFee();
    const service_fee = CartBilling.free_delivery ? 0 : SERVICE_FEE;
    let discountedTotal = isCheckoutBilling
      ? netSales
      : displaySubTotal - displayDiscount;
    let grandTotal = orderTippingAmount
      ? discountedTotal + orderTippingAmount
      : discountedTotal;
    if (savedOrderType === DELIVERY_ORDER_TYPE && cartItems?.length) {
      grandTotal = grandTotal + delivery_fee + service_fee;
    }
    return grandTotal >= 0 ? grandTotal : 0;
  };

  function calculateTotal(): number {
    let total = null;
    if (cartInCaseOfAddPaymentFlow) {
      total = cartInCaseOfAddPaymentFlow.tax
        ? calculateDiscountedTotal() + calculateTax()
        : calculateDiscountedTotal();
    } else {
      total = locationInfo?.selectedStore?.tax_rate
        ? calculateDiscountedTotal() + calculateTax()
        : calculateDiscountedTotal();
    }
    setTotal?.(Number(roundNumber(total, 2)));
    return total;
  }

  const handleClosePickupMethodModal = () => {
    setOpenPickupMethodModal(false);
  };
  const handleCloseDeliveryMethodModal = () => {
    setOpenDeliveryMethodModal(false);
    setOpenPickupMethodModal(false);
  };

  const showOrderTypeModal = () => {
    // setHandleChangeOrderType(true)
    if (isOrderTypeSelected && savedOrderType == PICK_UP_ORDER_TYPE) {
      setOpenPickupMethodModal(true);
      setOpenDeliveryMethodModal(false);
    } else {
      setOpenDeliveryMethodModal(true);
      setOpenPickupMethodModal(false);
    }
  };

  const closeOrderTypeModal = () => {
    setChangeOrderType(false);
    showOrderTypeModal();
  };

  const closeChangeOrderTypeModal = () => {
    setChangeOrderType(false);
  };

  const showChangeOrderType = (changeType) => {
    setChangeOrderType(changeType);
  };

  const totalForPlacedOrder = () => {
    const grandTotal = orderTippingAmount
      ? order?.total_price + orderTippingAmount
      : order?.total_price;
    return toFixedNumber(Number(grandTotal));
  };

  const taxShow = () => {
    if (handleTax) {
      return (
        <li className="d-flex justify-content-between">
          <div className="font-Visby-cf f-s16 f-w5">
            Tax{' '}
            {isTaxExempted ? (
              <span className=" clr-dark-green">(Tax Exempted)</span>
            ) : (
              ''
            )}{' '}
          </div>
          <span className="d-inline-block ms-3 f-s16 f-w5 font-Visby-cf">
            {isOrdered
              ? `$${roundNumber(tax, 2)}`
              : `$${toFixedNumber(cartTax)}`}
          </span>
        </li>
      );
    } else return null;
  };

  useEffect(() => {
    if (!isOrdered && isCheckoutBilling) {
      const netSales = toFixedNumber(displaySubTotal - displayDiscount);
      const grossSales =
        orderType === DELIVERY_ORDER_TYPE
          ? netSales + calculateDeliveryFee() + SERVICE_FEE
          : netSales;
      dispatch(
        updateCartBilling({
          sub_total: Number(roundNumber(grossSales, 2)),
          discount: displayDiscount,
          net_sales: netSales,
          total_price: Number(roundNumber(calculateTotal(), 2)),
          tax: isTaxExempt ? 0 : cartTax,
          gross_sales: displaySubTotal,
        }),
      );
    }
  }, [displaySubTotal, displayDiscount, orderTippingAmount, cartTax]);

  return (
    <>
      <div className={className}>
        <div
          className={`${
            handlePaymentForAddPaymentUser
              ? 'd-flex justify-content-between'
              : ''
          }`}
        >
          <h4
            className={`${
              handlePaymentForAddPaymentUser
                ? 'f-s20 text-start h-22 text-uppercase font-Cls fw-normal f-sm-s16 mt-3 mb-0'
                : 'text-start h-22 ls-normal lh-normal font-Cls fw-normal f-sm-s16 f-s20 mb-0'
            }`}
          >
            Order Total
          </h4>
        </div>
        <ul
          className={`list-inline ${
            handlePaymentForAddPaymentUser ? 'pt-0' : 'pt-3'
          }`}
        >
          {remainingAmount && remainingAmount > 0 ? (
            <li className={`text-danger d-flex justify-content-between`}>
              <div className="font-Visby-cf f-s16 f-w5">Remaining Amount </div>
              <div className="ms-3 f-w5">{`$${toFixedNumber(
                Number(remainingAmount),
              )}`}</div>
            </li>
          ) : null}
          <li className="d-flex justify-content-between">
            <div className="font-Visby-cf f-s16 f-w5">Subtotal </div>
            <span className="d-inline-block ms-3 f-s16 f-w5 font-Visby-cf">
              {isOrdered
                ? `$${order?.gross_sales?.toFixed(2)}`
                : `$${persistZero(toFixedNumber(displaySubTotal))}`}
            </span>
          </li>
          {order?.discount || displayDiscount > 0 ? (
            <li className={`text-danger d-flex justify-content-between`}>
              <div className="font-Visby-cf f-s16 f-w5">Discount </div>
              <div className="ms-3 f-w5">{`$${
                order?.discount || toFixedNumber(Number(displayDiscount))
              }`}</div>
            </li>
          ) : null}
          {taxShow()}
          {selectedTaxExempt && isTaxExempt && (
            <li className="d-flex justify-content-between">
              <div className="font-Visby-cf f-s16 f-w5 clr-dark-green">
                Tax Exemption
              </div>
              <span className="d-inline-block ms-3 f-s16 f-w5 font-Visby-cf clr-dark-green">
                {isOrdered
                  ? ` - $${roundNumber(tax, 2)}`
                  : ` - $${toFixedNumber(cartTax)}`}
              </span>
            </li>
          )}
          {orderTippingAmount ? (
            <li className={`d-flex justify-content-between`}>
              <div className="font-Visby-cf f-s16 f-w5">Tip </div>
              <span className="font-Visby-cf d-inline-block f-s16 f-w5 ms-3">
                ${`${roundNumber(orderTippingAmount, 2)}`}
              </span>
            </li>
          ) : null}
          {orderType == DELIVERY_ORDER_TYPE &&
          cartItems?.length &&
          !isOrdered ? (
            <>
              <li className={`d-flex justify-content-between`}>
                <div
                  className={`${
                    CartBilling.free_delivery && 'text-danger'
                  } font-Visby-cf f-s16 f-w5`}
                >
                  Delivery Fee{' '}
                </div>

                <span
                  className={`${
                    CartBilling.free_delivery && 'text-danger'
                  } d-inline-block ms-3  f-s16 f-w5 font-Visby-cf`}
                >
                  ${CartBilling.free_delivery ? 0 : calculateDeliveryFee()}
                </span>
              </li>
              {/* <li className={`d-flex justify-content-between`}>
                <div className="font-Visby-cf f-s16 f-w5"> Service Fee </div>

                <span className="d-inline-block ms-3 f-s16 f-w5 font-Visby-cf">
                  ${SERVICE_FEE}
                </span>
              </li> */}
            </>
          ) : null}
          {orderType == DELIVERY_ORDER_TYPE && isOrdered ? (
            <>
              {order?.delivery_fee ? (
                <li
                  className={`${
                    order?.free_delivery && 'text-danger'
                  } d-flex justify-content-between f-s16 f-w5`}
                >
                  Delivery Fee{' '}
                  <span
                    className={`d-inline-block ms-3 f-s16 f-w5 font-Visby-cf`}
                  >
                    ${order?.delivery_fee?.toFixed(2)}
                  </span>{' '}
                </li>
              ) : null}
              {order?.service_fee ? (
                <li className={`${order?.free_delivery && 'text-danger'}`}>
                  Service Fee{' '}
                  <span className="d-inline-block ms-3 font-Visby-cf">
                    ${order?.service_fee?.toFixed(2)}
                  </span>{' '}
                </li>
              ) : null}
            </>
          ) : null}
          <hr className="custom_hr_sty mb-2"></hr>
          <li className={`d-flex justify-content-between`}>
            <div className="font-Visby-cf f-s18 f-w8"> Total</div>
            {isOrdered ? (
              <a
                className="d-inline-block ms-3 text-decoration-none clr-dark-grey font-Visby-cf f-s18 f-w8"
                href="javascript:void(0);"
              >
                {isOrdered
                  ? `$${persistZero(totalForPlacedOrder())}`
                  : `$${persistZero(roundNumber(calculateTotal(), 2))}`}
              </a>
            ) : (
              <span className="d-inline-block ms-3 font-Visby-cf f-s18 f-w8">
                {isOrdered
                  ? `$${persistZero(totalForPlacedOrder())}`
                  : `$${persistZero(roundNumber(calculateTotal(), 2))}`}
              </span>
            )}
          </li>
        </ul>
        {!isCheckoutBilling && !isOrdered ? (
          <div className={styles.checkout_btn_wrap}>
            <button
              type="button"
              className={`btn-large w-100 text-uppercase mb-3 d-flex justify-content-between `}
              disabled={
                cartItems.length <= 0 ||
                isCartItemsUnavailable(cartItems) ||
                isCartModifiersUnavailable(cartItems) ||
                disableCheckout
              }
              onClick={onCheckout}
            >
              Checkout
              <span className="d-inline-block ms-3">
                ${persistZero(roundNumber(calculateTotal(), 2))}
              </span>
            </button>
            {isCart && isEditOrder ? (
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn-large w-100 text-uppercase mb-3 d-flex gap-2 justify-content-center align-items-center"
                  onClick={handleExitEditOrder}
                  disabled={isExitOrderLoading || syncingCart}
                >
                  Exit Edit Order
                  {(isExitOrderLoading || syncingCart) && <Spinner size="sm" />}
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : null}
      </div>
      {!isCheckoutBilling && (
        <ItemPickupDeliveryModal
          subTotal={toFixedNumber(displaySubTotal)}
          tax={toFixedNumber(calculateTax())}
          total={toFixedNumber(calculateTotal())}
          openPickupMethodModal={openPickupMethodModal}
          handleClosePickupMethodModal={handleClosePickupMethodModal}
          editPickupType={showChangeOrderType}
          editDeliveryType={showChangeOrderType}
          handleCloseDeliveryMethodModal={handleCloseDeliveryMethodModal}
          openDeliveryMethodModal={openDeliveryMethodModal}
          handleChangeOrderType={false}
        />
      )}
      {!isCheckoutBilling && (
        <CustomModal
          showModal={changeOrderType}
          title={''}
          closeModal={closeChangeOrderTypeModal}
          modalDialogClasses={'itemOrderModal'}
        >
          <ItemOrderModal
            handleShowDeliveryMethodModal={closeOrderTypeModal}
            handleShowPickupMethodModal={closeOrderTypeModal}
            closeOpenOrderModal={closeChangeOrderTypeModal}
            editChangeToDelivery={orderType == PICK_UP_ORDER_TYPE}
            editChangeToPickup={orderType == DELIVERY_ORDER_TYPE}
          />
        </CustomModal>
      )}

      {!isCheckoutBilling && (
        <GuestCheckoutModal
          showContactForm={showContactForm}
          handleCloseContactForm={handleCloseContactForm}
          currentGuestUserInfo={user}
          showContactInfo={showContactInfo}
          setShowContactInfo={setShowContactInfo}
          handleSubmitAsGuest={showOrderTypeModal}
          userStatusData={user}
        />
      )}
    </>
  );
};

export default Billing;
