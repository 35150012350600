import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import Layout from './components/Layout/Layout';
import { GOOGLE_SIGNIN_ID, PUBLISHABLEKEY } from './constants';
import { setRequestId, setVisitorId } from './helper/helperMethods';
import store, { persistor } from './redux/store/store';
import Routes from './routes/Routes/Routes';

import './App.scss';

import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from './components/ErrorBoundary';
import { brazeInititalize } from './helper/brazeHelper';
import Authentication from './Features/AccountSetup/components/UserAuthentication';
import { CampaignTriggerProvider } from './context/CampaignTriggerContext';
import Bugsnag from '@bugsnag/js';

brazeInititalize();

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any, query: any) => {
      const queryKey = query.queryKey[0];
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});
declare module 'react-query/types/react/QueryClientProvider' {
  export interface QueryClientProviderProps {
    children?: React.ReactNode;
  }
}

function App() {
  const stripePromise = loadStripe(PUBLISHABLEKEY);
  setVisitorId();
  setRequestId();

  return (
    <Provider store={store}>
      <PersistGate loading={<>loading...</>} persistor={persistor}>
        <div className="App">
          <GoogleOAuthProvider clientId={GOOGLE_SIGNIN_ID}>
            <QueryClientProvider client={queryClient}>
              <CampaignTriggerProvider>
                <BrowserRouter>
                  <ErrorBoundary>
                    <Elements stripe={stripePromise}>
                      <Layout>
                        <Authentication>
                          <Routes />
                        </Authentication>
                      </Layout>
                    </Elements>
                  </ErrorBoundary>
                  <ToastContainer />
                  <ReactQueryDevtools />
                </BrowserRouter>
              </CampaignTriggerProvider>
            </QueryClientProvider>
          </GoogleOAuthProvider>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
