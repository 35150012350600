import { logCustomEvent, logPurchase } from '@braze/web-sdk';

import * as CONSTANT from '../constants';
import { toFixedNumber } from '../priceCalculation/helper';

import { getUser, getUserId } from './helperMethods';
import { PickUpMethods } from './tracking';

import { changeUser, initialize } from '@braze/web-sdk';
import { format, parseISO } from 'date-fns';
import {
  IApplyOfferOnCart,
  IUpsellClickPayloadForBraze,
} from 'src/models/order.model';

export const brazeInititalize = () => {
  if (Boolean(import.meta.env.VITE_REACT_APP_ENABLE_BRAZE_TRACKING)) {
    initialize(import.meta.env.VITE_REACT_APP_BRAZE_API_KEY, {
      baseUrl: import.meta.env.VITE_REACT_APP_BRAZE_BASE_URL,
      enableLogging: Boolean(
        import.meta.env.VITE_REACT_APP_BRAZE_ENABLE_CONSOLE_LOGGING,
      ),
    });
  }
};

export const brazeSetUser = (userId: string) => {
  if (!Boolean(import.meta.env.VITE_REACT_APP_ENABLE_BRAZE_TRACKING)) return;
  changeUser(userId);
};

export const brazeLogCustomEvent = (eventName, eventProperties = null) => {
  if (!Boolean(import.meta.env.VITE_REACT_APP_ENABLE_BRAZE_TRACKING)) return;
  if (eventProperties) logCustomEvent(eventName, eventProperties);
  else logCustomEvent(eventName);
};

const getItemImageURL = (itemId: number) =>
  `${CONSTANT.s3BaseUrl}/Items/${itemId}/item-${itemId}.webp`;

const getMappedObject = (
  item: any,
  isOrderCancelledEvent: boolean,
  isCombo: boolean = false,
) => {
  return {
    itemName: item.name || item.modifier_name,
    ...(!isCombo && { price: item.price }),
    ...(item.item_size && { size: item.item_size }),
    ...(!isCombo &&
      !isOrderCancelledEvent && {
        image: getItemImageURL(item.item_id),
      }),
    quantity: item.quantity,
    ...(item.modifiers && {
      modsAdded: item?.modifiers?.map((modifier) => modifier.modifier_name),
    }),
  };
};

const getComboMappedItems = (
  comboItem: any,
  isOrderCancelledEvent: boolean,
) => {
  const comboMappedObject = {
    itemName: comboItem.name,
    price: comboItem?.quantity * comboItem.price,
    size: comboItem.item_size,
    ...(!isOrderCancelledEvent && {
      image: getItemImageURL(comboItem.item_id),
    }),
    quantity: comboItem.quantity,
    items: comboItem.modifiers.map((modifier_item) =>
      getMappedObject(modifier_item, isOrderCancelledEvent, true),
    ),
  };
  return comboMappedObject;
};

const getMappedItems = (
  items: any[],
  isOrderCancelledEvent: boolean = false,
) => {
  if (!items?.length) return [];
  const mappedItems = [];
  items?.forEach((item) => {
    if (
      [CONSTANT.TRY_TO_COMBO_ITEM, CONSTANT.KIDS_COMBO_ITEM].includes(
        item.combo_type,
      ) ||
      item.isKidsCombo ||
      item.isTry2Combo ||
      item.is_kids_combo ||
      item.is_try_2_combo
    )
      mappedItems.push(getComboMappedItems(item, isOrderCancelledEvent));
    else mappedItems.push(getMappedObject(item, isOrderCancelledEvent));
  });

  return mappedItems;
};

const getAccountType = (userType: string) =>
  userType === CONSTANT.GUEST_USER
    ? CONSTANT.GUEST_USER
    : CONSTANT.LOYALTY_USER;

const getIsLoyaltyRedeemed = (orderItems) => {
  return !!orderItems?.find((item) => item.discount);
};

export const brazeLogCustomEventLoyaltySignIn = (isItMobile: boolean) => {
  const user = getUser();
  const loyaltySignInPayload = {
    CzId: user.id,
    email: user.email,
    orderPlatform: isItMobile
      ? CONSTANT.SOURCE_MOBILE_WEB
      : CONSTANT.SOURCE_WEB,
  };
  brazeLogCustomEvent(CONSTANT.LOYALTY_SIGN_IN, loyaltySignInPayload);
};

export const brazeLogCustomEventOrderPlaced = (
  order: any,
  isItMobile: boolean,
) => {
  const promiseTime = order?.promise_time?.split(' ');
  const user = getUser();
  const categoriesOrdered = order?.items?.map((item) => item.category_name);
  const pickUpMethod = PickUpMethods[order?.order_types?.id];
  const orderPlacedPayload = {
    firstName: order?.customer?.first_name,
    lastName: order?.customer?.last_name,
    contact: order?.customer?.phone,
    email: order?.customer?.email,
    storeId: order?.location?.id,
    storeName: order?.location?.name,
    orderType: order?.order_types?.name,
    orderDate: promiseTime?.[0],
    orderTime: promiseTime?.[1],
    zipCode: order?.customer?.address?.zipcode || order?.location?.zip,
    orderPlatform: isItMobile
      ? CONSTANT.SOURCE_MOBILE_WEB
      : CONSTANT.SOURCE_WEB,
    accountType: getAccountType(user?.type),
    isTipGiven: Boolean(order?.tip),
    isLoyaltyRedeemed: getIsLoyaltyRedeemed(order?.items),
    categoriesOrdered: categoriesOrdered,
    items: getMappedItems(order?.items),
    subtotal: order?.sub_total,
    tax: order?.tax,
    discount: order?.discount,
    tipAmount: order?.tip,
    ...(pickUpMethod === CONSTANT.DELIVERY && {
      deliveryFee: toFixedNumber(
        (order?.sub_total - order?.discount) * CONSTANT.DELIVERY_FEE_PERCENT,
      ),
      serviceFee: CONSTANT.SERVICE_FEE,
    }),
    total: order?.total_price,
  };
  brazeLogCustomEvent(CONSTANT.ORDER_PLACED, orderPlacedPayload);
};

export const brazeLogCustomEventReOrder = (order: any, isItMobile: boolean) => {
  const categoriesOrdered = order?.items?.map((item) => item.category_name);
  const orderPlacedPayload = {
    orderPlatform: isItMobile
      ? CONSTANT.SOURCE_MOBILE_WEB
      : CONSTANT.SOURCE_WEB,
    categoriesOrdered: categoriesOrdered,
    items: getMappedItems(order?.items),
  };
  brazeLogCustomEvent(
    CONSTANT.REORDER_CLICKED_FROM_RECENT_ORDERS,
    orderPlacedPayload,
  );
};

export const brazeLogCustomEventOrderCancelled = (
  order: any,
  isItMobile: boolean,
) => {
  const promiseTime = order?.promise_time?.split(' ');
  const user = getUser();
  const categoriesOrdered = order?.items?.map((item) => item.category_name);
  const pickUpMethod = PickUpMethods[order?.order_types?.id];
  const orderCancelledPayload = {
    firstName: order?.customer?.first_name,
    lastName: order?.customer?.last_name,
    contact: order?.customer?.phone,
    email: order?.customer?.email,
    storeId: order?.store_details?.id,
    storeName: order?.store_details?.name,
    orderType: order?.order_types?.name,
    orderDate: promiseTime?.[0],
    orderTime: promiseTime?.[1],
    zipCode: order?.customer?.zipcode || order?.store_details?.zip,
    orderPlatform: isItMobile
      ? CONSTANT.SOURCE_MOBILE_WEB
      : CONSTANT.SOURCE_WEB,
    accountType: getAccountType(user?.type),
    isTipGiven: Boolean(order?.tip),
    isLoyaltyRedeemed: getIsLoyaltyRedeemed(order?.items),
    categoriesOrdered: categoriesOrdered,
    items: getMappedItems(order?.items, true),
    subtotal: order?.sub_total,
    tax: order?.tax,
    discount: order?.discount,
    tipAmount: order?.tip,
    ...(pickUpMethod === CONSTANT.DELIVERY && {
      deliveryFee: toFixedNumber(
        (order?.sub_total - order?.discount) * CONSTANT.DELIVERY_FEE_PERCENT,
      ),
      serviceFee: CONSTANT.SERVICE_FEE,
    }),
    total: order?.total_price,
  };
  brazeLogCustomEvent(CONSTANT.ORDER_CANCELLED, orderCancelledPayload);
};

interface IOrderAmount {
  discount: number;
  tax: number;
  subTotal: number;
  total: number;
}
export const brazeLogCustomEventCheckoutStarted = (
  cart: any,
  orderAmount: IOrderAmount,
  isItMobile: boolean,
  slotDetails: any,
  selectedStore: any,
) => {
  const user = getUser();
  const categoriesOrdered = cart?.items?.map((item) => item.category_name);
  const checkoutStartedPayload = {
    firstName: user.first_name,
    lastName: user?.last_name,
    contact: user?.phone,
    email: user?.email,
    storeId: selectedStore.id,
    storeName: selectedStore?.name,
    orderType: slotDetails?.pickupMethod || cart?.orderType,
    isAsapOrder: slotDetails?.isAsapOrder,
    orderDate: slotDetails?.date,
    orderTime: slotDetails?.isAsapOrder
      ? slotDetails?.asapMinutes
      : slotDetails?.time,
    zipCode:
      cart?.orderType === CONSTANT.DELIVERY_ORDER_TYPE
        ? cart?.orderDetails?.delivery_address?.zipcode
        : selectedStore.zip,
    orderPlatform: isItMobile
      ? CONSTANT.SOURCE_MOBILE_WEB
      : CONSTANT.SOURCE_WEB,
    cartId: cart?.cartId,
    discount: orderAmount?.discount,

    offerName: cart?.offerName,

    rootOfferId: cart?.root_offer_id,

    userOfferId: cart?.user_offer_id,
    accountType: getAccountType(user?.type),
    categoriesOrdered: categoriesOrdered,
    items: getMappedItems(cart?.items),
    subtotal: orderAmount?.subTotal,
    tax: orderAmount?.tax,
    ...(cart?.orderType === CONSTANT.DELIVERY_ORDER_TYPE && {
      deliveryFee: toFixedNumber(
        orderAmount?.subTotal * CONSTANT.DELIVERY_FEE_PERCENT,
      ),
      serviceFee: CONSTANT.SERVICE_FEE,
    }),
    total: orderAmount?.total,
  };
  brazeLogCustomEvent(CONSTANT.CHECKOUT_STARTED, checkoutStartedPayload);
};

export const brazeLogCustomEventAddedToCart = (
  cart: any,
  itemAdded: any,
  isItMobile: boolean,
  selectedStore: any,
) => {
  const user = getUser();
  const addedToCartPayload = {
    storeId: selectedStore.id,
    storeName: selectedStore?.name,
    orderType: cart?.orderType,
    cartId: cart?.cartId,
    zipCode:
      cart?.orderType === CONSTANT.DELIVERY_ORDER_TYPE
        ? cart?.orderDetails?.delivery_address?.zipcode
        : selectedStore.zip,
    orderPlatform: isItMobile
      ? CONSTANT.SOURCE_MOBILE_WEB
      : CONSTANT.SOURCE_WEB,
    accountType: getAccountType(user?.type),
    items: getMappedItems(itemAdded),
    total: toFixedNumber(itemAdded[0]?.price * itemAdded[0]?.quantity),
  };
  brazeLogCustomEvent(CONSTANT.ADDED_TO_CART, addedToCartPayload);
};

export const brazeLogCustomEventViewPage = (
  pageName: string,
  isItMobile: boolean,
) => {
  const user = getUser();
  const viewPagePayload = {
    pageName,
    orderPlatform: isItMobile
      ? CONSTANT.SOURCE_MOBILE_WEB
      : CONSTANT.SOURCE_WEB,
    accountType: getAccountType(user?.type),
  };
  brazeLogCustomEvent(CONSTANT.VIEW_PAGE, viewPagePayload);
};

export const brazeLogCustomEventLoyaltyAccountCreated = (userInfo) => {
  let dateOfBirth = '';
  if (userInfo.birthday) {
    const splitBirthday = userInfo.birthday.split('-');
    dateOfBirth = `${splitBirthday[1]}/${splitBirthday[2]}/${splitBirthday[0]}`;
  }
  let favoriteLocations = [];
  if (userInfo?.favorite_location)
    favoriteLocations = userInfo?.favorite_location?.map(
      (location) => location.label,
    );
  const loyaltyAccountCreatedPayload = {
    firstName: userInfo.first_name,
    lastName: userInfo.last_name,
    contact: userInfo.phone || '',
    email: userInfo.email,
    dateOfBirth,
    favoriteLocations,
  };
  brazeSetUser(getUserId());
  brazeLogCustomEvent(
    CONSTANT.LOYALTY_ACCOUNT_CREATED,
    loyaltyAccountCreatedPayload,
  );
};

export const brazeLogCustomEventAddToFavorites = (
  item: any,
  isItMobile: boolean,
) => {
  const user = getUser();
  const addToFavoritesPayload = {
    itemName: item.name,
    itemTag: item.tags.map((tag) => tag.name),
    itemDescription: item.description,
    itemCategory: item.category_name || '',
    price: item.price,
    image: getItemImageURL(item.id),
    orderPlatform: isItMobile
      ? CONSTANT.SOURCE_MOBILE_WEB
      : CONSTANT.SOURCE_WEB,
    accountType: getAccountType(user?.type),
  };
  brazeLogCustomEvent(CONSTANT.ADD_TO_FAVORITES, addToFavoritesPayload);
};

export const brazeLogCustomEventFormSubmission = (
  formName: string,
  isItMobile: boolean,
  formFields: any,
) => {
  const user = getUser();
  const formSubmissionPayload = {
    formName,
    orderPlatform: isItMobile
      ? CONSTANT.SOURCE_MOBILE_WEB
      : CONSTANT.SOURCE_WEB,
    accountType: getAccountType(user?.type),
    data: {
      ...formFields,
    },
  };
  brazeLogCustomEvent(CONSTANT.FORM_SUBMISSION, formSubmissionPayload);
};

const getMappedPurchaseItems = (data: any, isItMobile?: boolean) => {
  if (!data?.items?.length) return [];
  data?.items?.forEach((item: any) => {
    if (
      [CONSTANT.TRY_TO_COMBO_ITEM, CONSTANT.KIDS_COMBO_ITEM].includes(
        item?.combo_type,
      ) ||
      item?.isKidsCombo ||
      item?.isTry2Combo ||
      item?.is_kids_combo ||
      item?.is_try_2_combo
    )
      getComboMappedPurchaseItems(item, data, isItMobile);
    else getMappedPurchaseObject(item, data, null, isItMobile);
  });
};

const getMappedPurchaseObject = (
  item: any,
  data?: any,
  from?: string,
  isItMobile?: boolean,
) => {
  const promiseTime = data?.promise_time?.split(' ');
  const brazeObj = {
    orderId: data?.id,
    PosId: item?.brink_id || null,
    itemName: item?.name || item?.modifier_name || '',
    category: item?.category_name || '',
    size: item?.size || '',
    orderDate: promiseTime?.[0],
    orderTime: promiseTime?.[1],
    orderType: data?.order_types?.name,
    grossPrice: (item?.gross_price * item?.quantity).toFixed(2),
    price: item?.quantity * item?.price,
    storeId: data?.location?.id,
    storeName: data?.location?.name,
    image: getItemImageURL(item?.item_id),
    orderPlatform: isItMobile
      ? CONSTANT.SOURCE_MOBILE_WEB
      : CONSTANT.SOURCE_WEB,
    modsAdded: item?.modifiers_added?.length ? item?.modifiers_added : [],
  };
  if (from) {
    return brazeObj;
  }
  logPurchase(
    item?.name,
    item?.gross_price * item?.quantity,
    'USD',
    item?.quantity,
    brazeObj,
  );
};

const getComboMappedPurchaseItems = (
  comboItem: any,
  data: any,
  isItMobile?: boolean,
) => {
  const promiseTime = data?.promise_time?.split(' ');
  const comboMappedObject = {
    orderId: data?.id,
    PosId: comboItem?.brink_id || null,
    itemName: comboItem?.name || '',
    category: comboItem?.category_name || '',
    size: comboItem?.size || '',
    orderDate: promiseTime?.[0],
    orderTime: promiseTime?.[1],
    orderType: data?.order_types?.name,
    grossPrice: (comboItem?.gross_price || 0.0).toFixed(2),
    price: comboItem?.quantity * comboItem?.price,
    storeId: data?.location?.id,
    storeName: data?.location?.name,
    image: getItemImageURL(comboItem?.item_id),
    itemId: comboItem?.item_id,
    orderPlatform: isItMobile
      ? CONSTANT.SOURCE_MOBILE_WEB
      : CONSTANT.SOURCE_WEB,
    modsAdded: comboItem?.modifiers_added?.length
      ? comboItem?.modifiers_added
      : [],
    items:
      comboItem?.modifiers?.map((modifierItem) => {
        return getMappedPurchaseObject(modifierItem, data, 'combo', isItMobile);
      }) || [],
  };

  logPurchase(
    comboItem?.name,
    comboItem?.gross_price * comboItem?.quantity || 0.0,
    'USD',
    comboItem?.quantity,
    comboMappedObject,
  );
};

export const brazeLogOrderPlace = (orderedItems: any, isItMobile?: boolean) =>
  getMappedPurchaseItems(orderedItems, isItMobile);

export const brazeLogEventClaimPromoCode = (
  promo_code: string,
  userId: number,
  cartId: number,
) => {
  const claimPromoPayload = {
    customer_id: userId,
    cart_id: cartId,
    promo_code: promo_code,
    source: CONSTANT.SOURCE_WEB,
  };
  brazeLogCustomEvent(CONSTANT.CLAIM_PROMO_CODE, claimPromoPayload);
};

export const brazeLogEventOfferAdded = (
  offerData: IApplyOfferOnCart,
  userId: number,
  cartId: number,
) => {
  const offerAddedPayload = {
    root_offer_id: offerData?.root_offer_id,
    user_offer_id: offerData?.user_offer_id,
    eligible_item_offer_id: offerData?.eligible_item_offer_id,
    customer_id: userId,
    cart_id: cartId,
    source: CONSTANT.SOURCE_WEB,
  };
  brazeLogCustomEvent(CONSTANT.OFFER_ADDED_CART, offerAddedPayload);
};

export const brazeLogEventOnUpsellClick = (
  payload: IUpsellClickPayloadForBraze,
) => {
  brazeLogCustomEvent(CONSTANT.UPSELL_CLICK, payload);
};
