import { CZLogo } from 'src/assets/images/Svgs/svg';
import styles from './header.module.scss';
import { Col, Container, NavLink, Row } from 'react-bootstrap';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';

export default function TextToPayHeader() {
  const isItMobile = useCheckMobileScreen();

  return (
    <div className={`${styles.headerWrapper} top-0`}>
      <Container
        fluid
        className={`${styles.headerContainer} ps-md-1 pe-md-3 px-4 pt-4`}
      >
        <Row className={styles.headerRow}>
          <Col xs={12} className="p-0">
            <div className="d-flex justify-content-between align-items-center gap-md-5 mx-md-5 gap-4">
              <CZLogo className="sticky-logo add-payment-logo" />
              <p className="mb-0 pt-2 pt-md-0 text-uppercase f-w7 f-s16 f-sm-s14 font-Visby-cf">
                Catering Order Payment
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
